@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  overflow-x: hidden;
  
 font-family: 'Sofia Pro', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes appear {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.appear {
  animation: appear 1s forwards;
  animation-delay: 0.5s; /* you can adjust this delay as per your preference */
}